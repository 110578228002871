/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const HeartIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M6.7 2.7c.7 0 2.1.4 4.2 2.5l1.1 1 1.1-1.1c1.5-1.5 3.1-2.5 4.1-2.5 1.4 0 2.7.6 3.7 1.6 2.1 2.1 2.2 5.6 0 7.8-.1.1-4.6 5-8.4 9.1-.3.3-.7.3-1 0-.9-1-7.6-8.5-8.4-9.2C1 9.7 1 6.3 3.1 4.1c1-1 2.3-1.5 3.6-1.4zm0-1.5C5 1.1 3.3 1.8 2.1 3c-2.7 2.7-2.7 7.1 0 9.9.8.8 8.4 9.2 8.4 9.2.9.9 2.3.9 3.1 0 0 0 8.3-9 8.4-9 2.7-2.7 2.7-7.1 0-9.9-1.2-1.3-2.9-2-4.7-2-1.8 0-3.7 1.4-5.2 2.9-1.6-1.5-3.5-2.9-5.4-2.9z" />
	</svg>
))
HeartIconSvg.displayName = 'HeartIconSvg'

const HeartIcon = forwardRef((props, ref) => <Icon component={HeartIconSvg} ref={ref} {...props} />)
HeartIcon.displayName = 'HeartIcon'

HeartIcon.defaultProps = {
	...Icon.defaultProps,
}
HeartIcon.propTypes = {
	...Icon.propTypes,
}

export default HeartIcon
export { HeartIconSvg }
