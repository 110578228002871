import MenuItem from '@mui/material/MenuItem'
import { FormattedMessage } from 'react-intl'
import AccountIcon from '../../atoms/icons/account-icon'
import HeartIcon from '../../atoms/icons/heart-icon'
import SupportIcon from '../../atoms/icons/support-icon'
import { ChangeCountryLanguage } from '../change-country-language'
import Link from '../../molecules/link'
import { cx } from '@emotion/css'
import { ButtonIcon, Root } from './service-links.styled'
import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import { useMiniWishListContext } from '@bluheadless/ui-logic/src/providers/mini-wish-list'
import { useUser } from '@bluheadless/ui-logic/src/providers/user'

const ServiceLinks = ({ className, ...props }) => {
	const { contacts } = useConfig()
	const [user] = useUser()
	const { setOpen: setOpenMiniWishList } = useMiniWishListContext()

	return (
		<Root className={cx('ServiceLinks-root', className)} {...props}>
			<MenuItem>
				<Link underline="none" href="/login">
					<ButtonIcon
						badgeProps={user?.isLoggedIn ? { color: 'success', variant: 'dot' } : undefined}
						Icon={AccountIcon}
						variant="icon"
					/>

					<FormattedMessage id="sidebar_navigation_account" />
				</Link>
			</MenuItem>
			<MenuItem>
				<Link
					underline="none"
					onClick={() => {
						setOpenMiniWishList(true)
					}}
				>
					<HeartIcon fontSize="middle" />
					<FormattedMessage id="sidebar_navigation_wishlist" />
				</Link>
			</MenuItem>
			{contacts.enabled && (
				<MenuItem>
					<Link underline="none" href={contacts.path}>
						<SupportIcon fontSize="middle" />
						<FormattedMessage id="sidebar_navigation_customer_care" />
					</Link>
				</MenuItem>
			)}
			<MenuItem>
				<ChangeCountryLanguage className="change-country-language-trigger-sidebar" showFlag={false} />
			</MenuItem>
		</Root>
	)
}

export default ServiceLinks
