import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })`
	${({ message, position, theme }) =>
		position === 'top' || !message ? `border-bottom: 1px solid ${theme.palette.grey.light};` : ''}
`

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(2, 3)};
	.mini-trigger {
		padding: 0;
		.MuiSvgIcon-root {
			font-size: ${({ theme }) => theme.typography.pxToRem(19)};
		}
	}
`

const GridItem = styled(MuiGrid)``

const GridItemCenter = styled(MuiGrid)``

const LogoWrapper = styled('div')`
	margin: 0 auto;
	max-width: 60px;
	width: 100%;
`

export { GridContainer, GridItem, GridItemCenter, Header, LogoWrapper }
