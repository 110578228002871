/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const Bag2IconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M20.3 3.7h-4.5V2.3c0-1.2-1-2.3-2.3-2.3h-3C9.3 0 8.3 1 8.3 2.3v1.4H3.7c-.8 0-1.5.7-1.5 1.5v17.3c0 .8.7 1.5 1.5 1.5h16.5c.8 0 1.5-.7 1.5-1.5V5.2c.1-.8-.6-1.5-1.4-1.5zM9.8 2.3c0-.4.3-.8.8-.8h3c.4 0 .8.3.8.8v1.4H9.8V2.3zm10.5 20.2H3.7V5.2h4.5v1.5c0 .4.3.7.7.8H9c.4 0 .7-.2.8-.6V5.2h4.5v1.5c0 .4.3.7.6.8h.1c.4 0 .7-.3.8-.7V5.2h4.5v17.3z" />
	</svg>
))
Bag2IconSvg.displayName = 'Bag2IconSvg'

const Bag2Icon = forwardRef((props, ref) => <Icon component={Bag2IconSvg} ref={ref} {...props} />)
Bag2Icon.displayName = 'Bag2Icon'

Bag2Icon.defaultProps = {
	...Icon.defaultProps,
}
Bag2Icon.propTypes = {
	...Icon.propTypes,
}

export default Bag2Icon
export { Bag2IconSvg }
