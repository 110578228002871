import { styled } from '@mui/system'
import Button from '../../molecules/button'

const H1 = styled('h1')`
	margin-bottom: 0;
	margin-top: 0;
`

const ButtonIcon = styled(Button)`
	padding: 0;
	border: none;
	font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
	&:hover,
	&:active,
	&:focus {
		background: transparent;
		border: none;
	}
	.MuiSvgIcon-root {
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(20)};
	}
`

const Divider = styled('span')`
	background-color: ${({ theme }) => theme.palette.grey.light};
	height: 26px;
	width: 1px;
`

export { ButtonIcon, Divider, H1 }
