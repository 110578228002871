/**
 * AUTO-GENERATED from generate-icons script 🤖 - DO NOT EDIT!
 */

import { forwardRef } from 'react'
import Icon from '../icon/icon'

const AccountIconSvg = forwardRef((props, svgRef) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width={props.width || (props.height && Math.floor(+props.height * 1)) || 24}
		height={props.height || (props.width && Math.floor(+props.width / 1)) || 24}
		viewBox="0 0 24 24"
		fontSize="medium"
		ref={svgRef}
		{...props}
	>
		<path d="M11.999 1.502c2.548 0 5.251 2.146 5.251 3.748v3.752c0 1.443-.6 4.2-2.21 5.473a1.5 1.5 0 00.3 2.543l6.531 3.1a.837.837 0 01.626.923v1.461H1.499v-1.525a.9.9 0 01.627-.866l6.591-3.109a1.5 1.5 0 00.312-2.533c-1.559-1.252-2.285-4-2.285-5.468V5.249c0-1.567 2.73-3.747 5.255-3.747zm0-1.5c-3.313 0-6.756 2.764-6.756 5.247v3.753c0 1.638.748 4.954 2.847 6.638l-6.591 3.108s-1.5.668-1.5 1.5v2.254a1.5 1.5 0 001.5 1.5h21a1.5 1.5 0 001.5-1.5v-2.254c0-.882-1.5-1.5-1.5-1.5l-6.531-3.1c2.08-1.633 2.784-4.846 2.784-6.646V5.249c0-2.483-3.439-5.247-6.753-5.247z" />
	</svg>
))
AccountIconSvg.displayName = 'AccountIconSvg'

const AccountIcon = forwardRef((props, ref) => <Icon component={AccountIconSvg} ref={ref} {...props} />)
AccountIcon.displayName = 'AccountIcon'

AccountIcon.defaultProps = {
	...Icon.defaultProps,
}
AccountIcon.propTypes = {
	...Icon.propTypes,
}

export default AccountIcon
export { AccountIconSvg }
